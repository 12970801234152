#rainfall {
    width: calc(min(100vw - 40px, 1200px));
    margin: 10px auto;
}

#rainfall-list {
    width: 100%;
    height: 70vh;
    position: relative;
    margin: 10px;
    overflow: hidden;
}

.raindrop {
    width: 100px;
    left: 40px;
    position: absolute;
    border: solid 3px var(--text);
    animation: raindropFall 0s linear infinite;
    box-shadow: 0px 0px 10px 0px #000;
}

#rainfall-blur1 {
    backdrop-filter: blur(5px);
    position: absolute;
    top: 10%;
    height: 80%;
    width: 100%;
}
#rainfall-blur2 {
    backdrop-filter: blur(5px);
    width: 100%;
    top: 0%;
    height: 10%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: absolute;
}
#rainfall-blur3 {
    backdrop-filter: blur(5px);
    width: 100%;
    height: 10%;
    bottom: 0%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
}

@keyframes raindropFall {
    to {
        bottom: -50%;
    }
}