/**
 *  COPYRIGHT © 2022 MANU BHAT
 *  
 *  ALL RIGHTS RESERVED
 * 
 */

#work .skill-group {
    flex-direction: column;
    align-items: stretch;

}

#work .skill-item {
    align-self: stretch;
    width: fit-content;
}

#projects > p {
    font-family: var(--light);
    color: var(--text);
    width: fit-content;
    margin: 10px auto;
    padding: 0px 10px;
    backdrop-filter: blur(1px);
}

#projects-graph {
    margin: 30px auto 40px;

    width: min(100% - 20px, 800px);
    height:  min(100vw - 20px, 800px);

    position: relative;
}

.project {
    /* % of parent width */
    --vw: calc(min(100vw - 20px, 800px));
    --unit: calc(var(--vw) * 0.2);

    position: absolute;
    transition: all 0.2s;
}

.project-small {
    width: calc(var(--unit));
    height: calc(var(--unit));
}

.project-medium {
    --sqrt2: 1.414;
    width: calc(var(--unit) * var(--sqrt2));
    height: calc(var(--unit) * var(--sqrt2));
}

.project-large {
    width: calc(var(--unit) * 2);
    height: calc(var(--unit) * 2);
}

.project img {
    position: absolute;
    z-index: 0;

    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);

    border-radius: 10%;
    box-shadow: 0px 0px 8px 0px #000;

    transition: all 0.2s;
}

.project:hover {
    transform: scale(1.025);
}
.project img:hover {
    box-shadow: 0px 0px 10px 4px #000;
}

.project p {
    font-size: calc(0.11 * var(--unit));
}

.project-title {
    font-family: var(--bold);
    text-align: center;
    margin-top: 6px;

    /* helps with zindex stuff */
    position: relative;

    background-color: rgb(173, 216, 230, 0.6);
    border-radius: 5px;

    width: 80%;
    left: 10%;
}

.project-year {
    font-family: var(--light);
    text-align: center;

    position: absolute;
    margin-bottom: 5px;
    bottom: 0%;
    left: 10%;
    width: 80%;
}

