/**
 *  COPYRIGHT © 2022 MANU BHAT
 *  
 *  ALL RIGHTS RESERVED
 * 
 */

.body-theme-dark {
  --bg: #242e40;
  --accent: #FFFF00;
  --accent1: gold;
  --accent2: #92abd3;
  --accent3:#6795aa;
  --accent4:#8780cf;
  --link: #279ec6;

  --dots-r: 76;
  --dots-g: 107;
  --dots-b: 104;

  --streak1-r: 255;
  --streak1-g: 112;
  --streak1-b: 112;

  --streak2-r: 0;
  --streak2-g: 220;
  --streak2-b: 180;
  
  --text: #FFFFFF;

  --bold: 'Montserrat-bold', sans-serif;
  --light: 'Montserrat-light', sans-serif;
  --serif: 'PT serif', serif;
  --caps: 'Alegreya', sans-serif;
}

.body-theme-light {
  --bg: #DDEEEE;
  --accent: #FFFF00;
  --accent2: #92abd3;
  --accent3:#6795aa;/* rgb(74, 188, 241);*/
  --link: #279ec6;

  --text: #000000;

  --dots-r: 116;
  --dots-g: 127;
  --dots-b: 144;

  --streak1-r: 255;
  --streak1-g: 112;
  --streak1-b: 0;

  --streak2-r: 0;
  --streak2-g: 255;
  --streak2-b: 160;

  --bold: 'Montserrat-bold', sans-serif;
  --light: 'Montserrat-light', sans-serif;
  --serif: 'PT serif', serif;
  --caps: 'Alegreya', sans-serif; 
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0;
  background: var(--bg);
}

/* For sidebars and related stuff to work properly */

section {
  position: relative;
  /* z-index: -1; */
}

.anchor-translator {
  position: absolute;
  top: -100px;

  width: 0px;
  height: 0px;
}

.window-background {
  background-color: rgba(20, 20, 20, 0.2);
  backdrop-filter: blur(5px) contrast(0.8) brightness(0.9) hue-rotate(05deg);
  border: solid 0.75px #444;
  border-radius: 7px;
  /* double border */
  box-shadow: 0px 0px 1px 0.2px #000000;
}

/* FONTS */
@font-face {
  font-family: 'Montserrat-bold';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptserif/v17/EJRVQgYoZZY2vCFuvAFWzr-_dSb_.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v20/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYiFV0UzdYPFkZVO.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alegreyasanssc/v20/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1DipV9QREH4h2Mw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1 {
  font-weight: 100;
  font-family: var(--light);
  font-size: 28px;
  
  display: block;

  color: var(--text);
  border-bottom: var(--accent) ridge 2px;

  backdrop-filter: blur(1px);

  width: 350px;
  text-align: center;

  margin: 0 auto;
}
